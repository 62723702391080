export default class CaseWhen {
  constructor(params) {
    Object.assign(
      this,
      {
        operatorType: "equal",
        fromColumnId: null,
        fromValue: null,
        fromValues: [],
        fromValueStart: null,
        fromValueEnd: null,
        toValue: null,
        toColumnId: null
      },
      params
    );
  }

  static from(params) {
    return new CaseWhen(params);
  }
}

import Vue from "vue";
import { message } from "ant-design-vue";
import * as API from "@/api/node";
import * as APIDatasheet from "@/api/datasheet";
import * as Utils from "@/utils/function";
import selectIconFont from "@/components/select-iconfont";
import { ModelTree } from "@/views/datamodel/components/tree";
import Datasheet from "./datasheet";

export { ModelTree };

const getNodeTree = Utils.keepState(API.getNodeTree);

export class Workspace extends ModelTree {
  constructor(...params) {
    super(...params);
    if (this.isRoot) {
      /**
       * @type {string} activePane 用于标识当前打开的侧边栏，如「推送」、「API」
       */
      this.activePane = null;
      /**
       * @type {string} currentWorkspaceId 用于辅助实现响应式更新 currentWorkspace
       */
      this.currentWorkspaceId = null;
      /**
       * @type {string} currentDatasheetId 用于辅助实现响应式更新 currentDatasheet
       */
      this.currentDatasheetId = null;
    }
    if (this.isDatasheet) {
      /**
       * @type {Datasheet} 每个文件节点都有对应的 datasheet 对象，用于同步工作表状态
       */
      this.nodeDatasheet = new Datasheet(this);
    }
  }

  /**
   * 路由中的 workspaceId 对应的 node，即 workspace
   * @returns {Workspace}
   */
  get currentWorkspace() {
    return this.getNodeById(this.currentWorkspaceId);
  }

  /**
   * 路由中的 datasheetTd 对应的 node.nodeDatasheet
   * @returns {Datasheet}
   */
  get currentDatasheet() {
    /**
     * @type {Workspace}
     */
    const node = this.getNodeById(this.currentDatasheetId);
    if (node && node.nodeDatasheet) {
      return node.nodeDatasheet;
    }
    return new Datasheet();
  }

  async init() {
    if (this.isFolder) {
      // 更新 nodeId 和 nodeName 只是为了获取根节点的 nodeId 和 nodeName
      // 非根节点更新与否无影响
      this.nodeStatus.loading = true;
      const parentId = this.nodeId || null;
      const { data } = await getNodeTree({ parentId });
      this.nodeId = data.nodeId;
      this.nodeName = data.nodeName;
      this.setChildren(data.children);
      this.nodeStatus.loading = false;
    } else if (this.isDatasheet) {
      return this.nodeDatasheet.init();
    }
  }

  /**
   * 更新 children 元素的位置，顺便提交到服务器
   * Tips：目前同级别的节点来说，按照类型分组之后排序（目前只有三种节点：folder、datasheet、link）
   * @param {Array} children 改变位置的节点数组
   */
  updateChildrenPosition(children) {
    // 传入的数组长度比原来的小，则只包含了视图节点(目前来说是这样)，需要把别的节点也补回来
    if (children.length < this.children.length) {
      const otherChildren = this.children.filter(item => !item.isDatasheet);
      children = children.concat(otherChildren);
    }
    this.children = children;
    this.sortChildren();

    // 提交到服务器
    const req = children.map((node, index) => ({
      nodeId: node.nodeId,
      orderNo: index + 1
    }));
    API.sortNode(req).catch(() => {
      message.error({ content: "节点位置调整失败", duration: 2 });
    });
  }

  /**
   * 将此节点保存到服务器
   */
  syncNode() {
    const { nodeId, nodeName, nodeIcon } = this;
    return API.updateNode({ nodeId, nodeName, nodeIcon });
  }

  /**
   * 更新节点图标
   * @param {Event|Element} source selectIconFont 组件需要根据鼠标位置弹出菜单
   */
  async setIcon(source) {
    if (this.permissions.readonly) {
      return;
    }
    const opts = { source, light: true };
    const newIcon = await selectIconFont(this.nodeIcon, opts);
    this.nodeIcon = newIcon;
    return this.syncNode();
  }

  /**
   * 更新节点名称
   * @param {string} name 新名称
   */
  setName(name) {
    name = String(name || "").trim();
    if (name && this.nodeName !== name) {
      this.nodeName = name;
      return this.syncNode();
    }
  }

  /**
   * 视图导出为 CSV 文件
   */
  async exportCSV() {
    const loadingKey = "export-csv";
    try {
      const hide = message.loading({
        content: "正在导出...",
        key: loadingKey,
        duration: 0
      });
      const { data } = await APIDatasheet.exportCSV(this.nodeId);
      if (!data) {
        throw data;
      }
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const a = document.createElement("a");
      a.download = `${this.nodeName}.csv`;
      a.href = URL.createObjectURL(blob);
      a.click();
      hide();
    } catch {
      message.error({
        content: "导出失败",
        key: loadingKey,
        duration: 2
      });
    }
  }

  async clone() {
    if (!this.isDatasheet) {
      return null;
    }
    const { data } = await APIDatasheet.copyDatasheet(this.nodeId);
    const workspace = new Workspace(data, this.parent);
    this.parent.children.push(workspace);
    return workspace;
  }

  async remove() {
    await API.deleteNode(this.nodeId);
    const parent = this.parent;
    if (this.isFolder) {
      this.closeDatasheets();
    } else if (this.isDatasheet) {
      this.nodeDatasheet.destroy();
    }
    if (parent) {
      const index = parent.children.indexOf(this);
      if (index >= 0) {
        parent.children.splice(index, 1);
      }
    }
  }

  /**
   * 关闭 websocket 连接
   * @returns {void}
   */
  closeDatasheets() {
    const workspace = this.currentWorkspace;
    if (!workspace) {
      return;
    }
    for (const item of workspace.children) {
      if (item.nodeDatasheet) {
        item.nodeDatasheet.destroy();
      }
    }
  }
}

// 新建根节点时传入 children: [] 标识此节点 isFolder 为 true
const workspace = new Workspace({ children: [] });

export default Vue.observable(workspace);

/**
 * @param {string} action 操作名称
 * @returns {Function}
 */
function makeAction(action) {
  /**
   * @param {any} action 需要发送的数据，可选
   * @param {Function} middleware chunk 发送前的处理方法，可选
   * @returns {Object}
   */
  return (data = null, middleware) => {
    const chunk = { action, data };
    if (middleware) {
      middleware(chunk);
    }
    return chunk;
  };
}

export default {
  // 初始化工作表（工作台）
  initDatasheet: makeAction("init_dsh"),
  // 初始化工作表（数据集）
  initDataset: makeAction("init_ds"),
  // 选择多列
  select: makeAction("select_col"),
  // 列属性修改
  alter: makeAction("alter_col"),
  // 新增列（单个）
  add: makeAction("add_col"),
  // 移除列
  remove: makeAction("rm_col"),
  // 聚合操作
  agg: makeAction("agg_col"),
  // 列变换（表达式）操作
  trans: makeAction("trans_col"),
  // 条件变换（case when）操作
  casewhen: makeAction("casewhen_col"),
  // 排序
  sort: makeAction("sort_cols"),
  // 分组
  group: makeAction("group_col"),
  // 修改列类型（现统一放在 alter 操作）
  cast: makeAction("cast_col"),
  // 修改时间格式化类型（现统一放在 alter 操作）
  format: makeAction("fmt_col"),
  // 新增公式列
  insert: makeAction("insert_col"),
  // 复制列
  clone: makeAction("clone_col"),
  // 过滤
  filter: makeAction("add_filters"),
  // 获取过滤器值
  filterValues: makeAction("filter_val"),
  // 获取列的值
  columnValues: makeAction("col_val"),
  // 同比
  yearOnYear: makeAction("yoy"),
  // 环比
  monthOnMonth: makeAction("mom"),
  // 编辑单元格
  cell: makeAction("edit_cell"),
  // 移动列
  move: makeAction("mv_col"),
  // 调整列宽
  resize: makeAction("resize_col"),
  // 翻页
  page: makeAction("page"),
  // 撤销
  undo: makeAction("undo"),
  // 重做
  redo: makeAction("redo"),
  // topn
  topn: makeAction("topn"),
  // 转置
  transpose: makeAction("transpose"),
  // 统计
  stats: makeAction("stats"),
  // SQL 查询
  query: makeAction("sql"),
  // 表关联
  join: makeAction("join")
};

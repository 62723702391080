<template>
  <div :style="styles">
    <i>暂无数据</i>
  </div>
</template>

<script>
export default {
  props: {
    indentLevel: Number
  },
  computed: {
    styles() {
      return {
        paddingLeft: `${this.indentLevel * 14}px`,
        textAlign: "center",
        lineHeight: "46px",
        color: "rgba(0, 0, 0, 0.2)",
        userSelect: "none"
      };
    }
  }
};
</script>

import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/node") });

interceptor.auth(axios);

/**
 * 节点 CRUD
 */
export function updateNode(data) {
  return axios.post("/update", data);
}

export function createNode(data) {
  return axios.post("/create", data);
}

export function moveNode(data) {
  return axios.post("/move", data);
}

export function deleteNode(nodeId) {
  return axios.delete("/delete/" + nodeId);
}

export function getNodeTree(params) {
  return axios.get("/tree", { params });
}

export function getFolderNode(nodeId, params) {
  return axios.get("/folder/" + nodeId, { params });
}

/**
 * 节点权限
 */
export function getNodeRoleList(nodeId) {
  return axios.get("/role/list", { params: { nodeId } });
}

export function updateNodeRole(data) {
  return axios.post("/role/update", data);
}

export function addNodeRole(data) {
  return axios.post("/role/add", data);
}

export function deleteNodeRole(data) {
  return axios.delete("/role/delete", { data });
}

export function switchNodeRoleExtend(data) {
  return axios.post("/role/switch", data);
}

/**
 * 分享节点
 */
export function setNodeShare(data) {
  return axios.post("/share", data);
}

export function deleteNodeShare(nodeId) {
  return axios.delete("/share/cancel/" + nodeId);
}

export function getNodeShare(nodeId) {
  return axios.get("/share/" + nodeId);
}

/**
 * 上传节点
 */
export function uploadNodeFile(file, parentId = null) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("parentId", parentId);
  return axios.post("/import", formData);
}

/**
 * 节点排序
 */
export function sortNode(children) {
  return axios.post("/sortBatch", children);
}

export default axios;

import * as API from "@/api/datasheet";
import Actions from "@/constant/datasheet-actions";
import { keepState } from "@/utils/function";
import notification from "@/utils/notification-box";
import WorksheetSocket from "../utils/worksheet-socket";
import Condition from "./condition";

// eslint-disable-next-line no-unused-vars
import { Workspace } from "./workspace";

/**
 * 最初的设计中，侧边栏的目录被称为 workspace，即项目。
 * 每个 workspace 中的文件被称为 datasheet，即视图。
 * 而每个 datasheet 中可以包含多个 view，即视图，所有视图都是来自同一份数据。
 *
 * @date 2022-04
 * @typedef {{ start: number, end: number }} Range
 * @typedef {{ enable: boolean, sql: string }} Query
 */
export default class Datasheet {
  /**
   * @param {Workspace} node datasheet 关联的节点
   */
  constructor(node) {
    this[Datasheet.PROP_NODE] = node;
    this.nodeId = (node && node.nodeId) || null;
    // 每个 datasheet 关联的数据源中的列，不一定被选入工作表
    this.fieldList = [];
    // 视图 ID
    this.datasheetId = null;
    // datasheet 关联的 Dataset ID (数据源 ID)
    this.datasetId = null;
    // dataset 类型，table or view
    this.datasetType = null;
    // datasheet 关联的「目标库连接器」ID
    this.destinationId = null;
    // 每个视图维护各自的 ws 实例
    this.socket = null;
    // 标识视图是否正在加载分页数据，滚动加载分页数据时借助此标识来
    // 避免多次滚动事件造成重复加载分页数据问题
    this.loadingPageData = false;
    // 视图加载到的页码
    this.page = 1;
    // 视图总记录数
    this.total = 0;
    // 视图中的列，有可能是公式列。因此和 fiedList 是交叉关系
    this.columns = [];
    // 视图数据
    this.data = [];
    // 设置了分组列时的分组数据
    this.groupData = [];
    /**
     * 视图 sql 功能
     * @type {Query}
     */
    this.query = { enable: false, sql: "" };
    this.joins = [];
    // 底部的统计数据
    this.statistics = null;
    // 视图过滤器配置
    this.filter = new Condition.Filter();
    // 视图排序配置
    this.sort = new Condition();
    // 视图分组配置，（groupData 是分组后的视图数据）
    this.group = new Condition();
    /**
     * top-n 配置
     * @type {Range}
     */
    this.topn = { start: null, end: null };
    // 转置配置（暂时弃用转置功能，2022-04）
    this.transpose = null;
    // 是否可重做，服务端会通过 socket 返回该状态
    this.redoable = false;
    // 是否可撤销，服务端会通过 socket 返回该状态
    this.undoable = false;
  }

  /**
   * @returns {Workspace} 返回关联的节点
   */
  get node() {
    return this[Datasheet.PROP_NODE];
  }

  get loading() {
    if (!this.node) {
      return false;
    }
    return this.node.nodeStatus.loading;
  }
  set loading(value) {
    if (this.node) {
      this.node.nodeStatus.loading = value;
    }
  }

  /**
   * 历史原因，接口返回的 datasheet 是视图列表。
   * 但目前的需求变更已经没有「视图」的概念，暂时不改动接口。
   *
   * 因此取 datasheet[0] 作为当前 datasheet 的工作表内容。
   *
   * @date 2022-04
   */
  init = keepState(async function init() {
    // socket 存在说明已经初始化
    if (this.socket || !this.node) {
      return;
    }
    this.loading = true;
    const { data } = await API.getDatasheet(this.node.nodeId);
    const datasheet = data.datasheet;
    if (!datasheet) {
      throw data;
    }
    this.fieldList = data.fieldList || [];
    this.datasheetId = datasheet.datasheetId;
    this.datasetId = datasheet.datasetId;
    this.datasetType = datasheet.datasetType;
    this.destinationId = datasheet.destinationId;

    const socket = new WorksheetSocket({ spaceId: this.node.spaceId });
    const size = WorksheetSocket.PAGE_SIZE;
    const action = Actions.initDatasheet({ ...datasheet, size });
    await socket.updateToken();
    socket.datasheetId = datasheet.datasheetId;
    this.socket = socket;

    socket.on("update-columns", value => {
      this.columns = value;
    });
    socket.on("update-filter", value => {
      this.filter = new Condition.Filter(value);
    });
    socket.on("update-group", value => {
      this.group = new Condition(value);
    });
    socket.on("update-sort", value => {
      this.sort = new Condition(value);
    });
    socket.on("update-query", value => {
      /**
       * @type {Query}
       */
      this.query = Object.assign({ enable: false, sql: "" }, value);
    });
    socket.on("update-topn", value => {
      /**
       * @type {Range}
       */
      this.topn = Object.assign({ start: null, end: null }, value);
    });
    socket.on("update-joins", value => {
      this.joins = value;
    });
    socket.on("update-total", value => {
      this.total = value;
    });
    socket.on("update-loading", value => {
      this.loading = value;
    });
    socket.on("update-undoable", value => {
      this.undoable = value;
    });
    socket.on("update-redoable", value => {
      this.redoable = value;
    });
    socket.on("update-transpose", value => {
      this.transpose = value;
    });
    socket.on("update-statistics", value => {
      this.statistics = value;
    });
    socket.on("update-data", data => {
      const { records = [], page = 1 } = data;
      const startIndex = page > 1 ? this.data.length : 0;
      records.forEach((item, index) => {
        item.rowIndex = index + startIndex;
        Object.freeze(item);
      });
      if (page > 1) {
        // page > 1 表示加载的是分页数据，需追加到工作表
        this.data = this.data.concat(records);
      } else {
        // 否则为第一页数据，需要替换当前数据，并滚动到初始位置
        this.data = records;
      }
      this.groupData = data.groups || [];
      this.page = page;
    });
    socket.on("on-reconnect", async () => {
      await socket.updateToken();
      socket.reconnect(action);
    });
    // 被动关闭连接（因服务异常或网络异常）时，清空所有视图的 loading 状态
    socket.on("on-close", () => {
      this.loading = false;
    });
    socket.send(action);
  });

  destroy() {
    if (this.socket) {
      this.socket.close();
    }
    this.fieldList = [];
    this.columns = [];
    this.data = [];
    this.groupData = [];
    this.statistics = null;
    this.socket = null;
  }

  /**
   * 代理当前视图的 socket.send 方法
   * @param {Object} action 工作表操作
   */
  send(action) {
    const permissions = this.node ? this.node.permissions : {};
    if (permissions.readonly && !this.__noticed__ && action.action !== "page") {
      this.__noticed__ = true;
      notification.show({
        type: "warning",
        message: "操作提示",
        description: "只读权限，操作结果不会保存。"
      });
    }
    if (this.socket) {
      return this.socket.send(action);
    }
    return Promise.reject(action);
  }

  /**
   * 重写 toJSON 方法，拒绝被序列化，避免发送无效数据至服务端
   */
  toJSON() {
    return void 0;
  }

  static PROP_NODE = Symbol.for("node of datasheet");
}

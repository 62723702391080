/**
 * flatten an array
 * @param {Array} list source list
 * @param {String} [key=children] flatten by prop
 * @returns {Array} flattened array
 */
export function flatten(list, key = "children") {
  if (!Array.isArray(list)) return [];
  return list.reduce(
    (arr, item) => arr.concat(item, flatten(item[key] || [], key)),
    []
  );
}

/**
 * create map from source list by specified prop
 * @param {Array} list source list
 * @param {String} prop create by prop
 * @returns {Object} map
 */
export function hashmap(list, prop) {
  if (!Array.isArray(list)) return null;
  return list.reduce((map, item) => {
    const key = item[prop];
    map[key] = item;
    return map;
  }, {});
}

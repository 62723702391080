import throttle from "lodash/throttle";

/**
 * rect 是否相交
 * @param {DOMRect} box1
 * @param {DOMRect} box2
 */
function intersect(box1, box2) {
  return !(
    box1.top >= box2.bottom ||
    box1.bottom <= box2.top ||
    box1.left >= box2.right ||
    box1.right <= box2.left
  );
}

function detect(container) {
  const box1 = container.getBoundingClientRect();
  const imgs = Array.from(container.querySelectorAll("img"));
  for (const item of imgs) {
    const dataset = item.dataset;
    if (!dataset.done && intersect(box1, item.getBoundingClientRect())) {
      item.src = dataset.src;
      dataset.done = true;
      delete dataset.src;
    }
  }
}

export default {
  /**
   * @param {HTMLElement} el
   */
  bind(el) {
    const onScroll = throttle(() => {
      detect(el);
    }, 100);
    el.addEventListener("scroll", onScroll);
    // contextify 存在动画,无法判断哪些图标需要立即初始化。所以延迟 60ms 再检测
    setTimeout(() => {
      detect(el);
    }, 60);
  },
  componentUpdated(el) {
    detect(el);
  }
};

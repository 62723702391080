class Status {
  // 是否展开结点
  expanded = false;
  // 结点是否加载中
  loading = true;
  // 结点处于重命名状态，用于侧边栏列表树
  renaming = false;
  // 结点处于重命名状态，用于 <header /> 的标题
  renamingAtHeader = false;
  // 结点处于重命名状态，用于 <tabs /> 的项目列表 context
  renamingAtContext = false;
  constructor(opts) {
    Object.assign(this, opts);
  }
}

class Permission {
  manageable = false;
  editable = false;
  constructor(opts) {
    Object.assign(this, opts);
  }
  get readonly() {
    return !(this.manageable || this.editable);
  }
}

export default class Tree {
  /**
   * @param {Object} opts node options
   * @param {String|Number} opts.nodeId
   * @param {String} opts.nodeName
   * @param {Array} opts.children 可选，children 为数组时，nodeType 将会是 FOLDER
   * @param {Tree} parent
   */
  constructor(opts, parent) {
    opts = opts || {};
    Object.assign(this, opts);
    this.nodeId = opts.nodeId || null;
    this.nodeName = opts.nodeName;
    this.nodeIcon = opts.nodeIcon || null;
    this.nodeStatus = new Status();
    this.permissions = new Permission(opts.permissions);
    this[Tree.PROP_PARENT] = parent || null;
    if (Array.isArray(opts.children)) {
      this.nodeType = Tree.NODE_FOLDER;
      this.children = [];
      this.setChildren(opts.children);
    } else {
      this.nodeType = opts.nodeType
        ? Number(opts.nodeType)
        : Tree.NODE_DATASHEET;
      this.children = null;
    }
    this.sortChildren();
  }
  /**
   * 读取 nodeIcon，nodeIcon 为空则回退到默认图标
   * @returns {string}
   */
  get icon() {
    if (this.nodeIcon) {
      return this.nodeIcon;
    }
    const defaultIcons = [
      Tree.ICON_FOLDER,
      Tree.ICON_DATASHEET,
      Tree.ICON_LINK,
      Tree.ICON_DASHBOARD
    ];
    return defaultIcons[this.nodeType];
  }
  /**
   * @returns {Tree}
   */
  get parent() {
    return this[Tree.PROP_PARENT] || null;
  }
  /**
   * @returns {Tree}
   */
  get root() {
    let node = this.parent;
    while (node) {
      if (!node.parent) {
        break;
      }
      node = node.parent;
    }
    return node;
  }
  get isFolder() {
    return this.nodeType === Tree.NODE_FOLDER;
  }
  get isDatasheet() {
    return this.nodeType === Tree.NODE_DATASHEET;
  }
  get isLink() {
    return this.nodeType === Tree.NODE_LINK;
  }
  get isDashboard() {
    return this.nodeType === Tree.NODE_DASHBOARD;
  }
  get isRoot() {
    return !this.parent;
  }
  get isEmpty() {
    return this.children ? !this.children.length : true;
  }

  /**
   * 判断 node 是否为当前 Tree 的子树（或子节点）
   * @param {Tree} node
   * @returns {Boolean}
   */
  contains(node) {
    if (this === node) {
      return true;
    }
    if (this.isFolder) {
      for (const subtree of this.children) {
        if (subtree.contains(node)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * 更新当前 Tree 的 children
   * @param {Array} children
   * @returns {Boolean} 是否更新成功
   */
  setChildren(children) {
    if (this.isFolder && Array.isArray(children)) {
      const Ctor = this.constructor;
      this.children = children.map(item => new Ctor(item, this));
      return true;
    }
    return false;
  }
  /**
   * 添加节点至文件夹
   * @param {object} data
   * @returns {Tree}
   */
  addChild(data) {
    if (!this.isFolder) {
      return null;
    }
    const node = new this.constructor(data, this);
    this.children.push(node);
    return node;
  }
  /**
   * 对于 children 按照 dashboard、folder、datasheet、link、other 的分组，分组顺序排序（other 代表未来的其它类型）
   */
  sortChildren() {
    if (!this.children) return;

    const folders = [],
      datasheets = [],
      links = [],
      others = [],
      dashboard = [];
    this.children.forEach(item => {
      switch (item.nodeType) {
        case Tree.NODE_FOLDER:
          folders.push(item);
          break;
        case Tree.NODE_DATASHEET:
          datasheets.push(item);
          break;
        case Tree.NODE_LINK:
          links.push(item);
          break;
        case Tree.NODE_DASHBOARD:
          dashboard.push(item);
          break;
        default:
          others.push(item);
      }
    });
    this.children = [].concat(dashboard, folders, datasheets, links, others);
  }

  /**
   * @param {string} nodeId
   * @returns {Tree}
   */
  getNodeById(nodeId) {
    if (this.nodeId == nodeId) {
      return this;
    }
    if (this.isFolder) {
      for (const item of this.children) {
        const node = item.getNodeById(nodeId);
        if (node) {
          return node;
        }
      }
    }
    return null;
  }
  toggleExpand() {
    this.nodeStatus.expanded = !this.nodeStatus.expanded;
  }

  setIcon(icon) {
    this.nodeIcon = icon;
  }
  setName(name) {
    this.name = name;
  }
  // folder 可以继续展开，file 作为叶子结点不可继续展开
  static NODE_FOLDER = 0;
  static NODE_DATASHEET = 1;
  static NODE_LINK = 2;
  static NODE_DASHBOARD = 3;
  // parent 指针属性
  static PROP_PARENT = Symbol.for("parent node");
  // 默认图标
  static ICON_FOLDER = "series1/文件夹 61.svg";
  static ICON_DATASHEET = "series1/文件 60.svg";
  static ICON_LINK = "series1/发现 91.svg";
  static ICON_DASHBOARD = "series1/收益 76.svg";
}

import { notification } from "ant-design-vue";

/**
 * 消息盒子
 * @todo 多条消息时的已读、下一条功能
 * @todo 多条消息进来时，复用同一个通知实例
 */
export default {
  show(config = {}) {
    const type = config.type || "info";
    notification[type](config);
  },
  /**
   * @param {String} key
   */
  close(key) {
    notification.close(key);
  },
  destroy() {
    notification.destroy();
  }
};

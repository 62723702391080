<template>
  <div v-if="node.isFolder">
    <slot>
      <TreeNode
        :node="node"
        :indent-level="indentLevel"
        :is-active="isActive"
        :readonly="readonly"
        :add-class="addClass"
        v-on="$listeners"
      />
    </slot>
    <div v-if="node.nodeStatus.expanded">
      <component
        :is="dragComponent"
        v-model="nodeChildren"
        :group="node.nodeId"
        :animation="200"
        :delay="dragDelay"
      >
        <noodle-tree
          v-for="subtree in nodeChildren"
          :key="subtree.nodeId"
          :readonly="readonly"
          :node="subtree"
          :indent-level="indentLevel + 1"
          :active-nodes="activeNodes"
          :add-class="addClass"
          v-on="$listeners"
        />
      </component>
      <NodeEmpty v-if="node.isEmpty" :indent-level="indentLevel + 1" />
    </div>
  </div>
  <TreeNode
    v-else
    :node="node"
    :readonly="readonly"
    :indent-level="indentLevel"
    :is-active="isActive"
    :add-class="addClass"
    v-on="$listeners"
  />
</template>

<script>
import Draggable from "vuedraggable";
import ModelTree from "./model-tree";
import TreeNode from "./render-tree-node";
import NodeEmpty from "./NodeEmpty";

export default {
  name: "noodle-tree",
  components: { Draggable, TreeNode, NodeEmpty },
  props: {
    node: ModelTree,
    readonly: Boolean,
    activeNodes: [String, Array],
    addClass: Function,
    indentLevel: {
      type: Number,
      default: 1
    }
  },
  computed: {
    isActive() {
      const nodes = [].concat(this.activeNodes);
      return nodes.includes(this.node.nodeId);
    },
    nodeChildren: {
      get() {
        return this.node.children || [];
      },
      set(children) {
        this.node.updateChildrenPosition(children);
      }
    },
    dragComponent() {
      const permissions = this.node.permissions || { manageable: false };
      return permissions.manageable ? "Draggable" : "div";
    },
    dragDelay() {
      return "ontouchstart" in document.documentElement ? 100 : 0;
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.tree-node {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-right: 8px;
  height: @item-height;
  user-select: none;
  cursor: pointer;
  &:hover,
  &.active {
    background: @bg-focused;
  }
  .focusable {
    flex-shrink: 0;
  }
  .ndl-icon-chevron-right {
    transition: transform 0.2s;
  }
  &.expanded .ndl-icon-chevron-right {
    transform: rotate(90deg);
  }
  .node-icon {
    font-size: 20px;
    color: inherit;
  }
  .auto-hide {
    display: none;
  }
  &:hover .auto-hide {
    display: flex;
  }
}
</style>

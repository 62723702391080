import * as Model from "@/model";
import CaseWhenRule from "../components/context-case-when/src/model-casewhen";

export class Condition {
  constructor(data) {
    data = data || {};
    /**
     * @type {array}
     */
    this.conditions = data.conditions || [];
    /**
     * @type {string}
     */
    this.type = data.type || null;
  }

  static from(data) {
    return new Condition(data);
  }
}

export class Filter extends Condition {
  constructor(data) {
    super(data);
    this.conditions = this.conditions.map(Model.Filter.from);
  }
  static from(data) {
    return new Filter(data);
  }
}

export class CaseWhen extends Condition {
  constructor(data) {
    super(data);
    this.conditions = this.conditions.map(CaseWhenRule.from);
  }
  static from(data) {
    return new CaseWhen(data);
  }
}

/**
 * 过滤器
 */
Condition.Filter = Filter;
/**
 * 条件转换
 */
Condition.CaseWhen = CaseWhen;

export default Condition;

import Iconfont from "@/components/iconfont";
import Focusable from "../../focusable";
import NodeTitle from "./NodeTitle";

function resolveClass(addClass, node) {
  if (typeof addClass === "function") {
    return addClass(node);
  }
  return addClass || null;
}

function render(h, context) {
  const props = context.props;
  const listeners = context.listeners;
  const node = props.node;

  function onNodeClick(evt) {
    if (listeners.select) {
      listeners.select(node, evt);
    }
  }

  function onOptionsClick(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (listeners.contextmenu) {
      listeners.contextmenu(node, evt);
    }
  }

  function onAdd(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (listeners.add) {
      listeners.add(node, evt);
    }
  }

  function onArrowClick(evt) {
    evt.stopPropagation();
    node.toggleExpand();
  }

  function onIconClick(evt) {
    if (!props.readonly) {
      node.setIcon(evt.target);
    }
  }

  return (
    <div
      class={[
        "tree-node",
        { expanded: node.nodeStatus.expanded, active: props.isActive },
        resolveClass(props.addClass, node)
      ]}
      style={{ paddingLeft: `${props.indentLevel * 14}px` }}
      onClick={onNodeClick}
      onContextmenu={onOptionsClick}
    >
      <Focusable class="node-icon" onClick={onIconClick}>
        <Iconfont type={node.icon} />
      </Focusable>
      <NodeTitle node={node} />
      {node.isFolder && !props.readonly ? (
        <Focusable class="auto-hide" onClick={onAdd}>
          <i class="ndl-icon-plus"></i>
        </Focusable>
      ) : null}
      {props.readonly ? null : (
        <Focusable class="auto-hide" onClick={onOptionsClick}>
          <i class="ndl-icon-more-horizontal"></i>
        </Focusable>
      )}
      {node.isFolder ? (
        <Focusable onClick={onArrowClick}>
          <i class="ndl-icon-chevron-right"></i>
        </Focusable>
      ) : null}
    </div>
  );
}

export default { functional: true, render };

import showContext from "@/components/contextify";
import SelectIconFont from "./src/SelectIconFont";

export default function(type, opts) {
  return new Promise(resolve => {
    const onSelect = val => {
      resolve(val);
      if (vm) vm.hide();
    };
    const context = {
      render() {
        return <SelectIconFont type={type} onSelect={onSelect} />;
      }
    };
    const vm = showContext(context, opts);
  });
}

import isObject from "lodash/isObject";
import merge from "lodash/merge";
import Observer from "./observer";

const Status = {
  UNINITIALIZED: -1,
  CONNECTING: WebSocket.CONNECTING,
  OPEN: WebSocket.OPEN,
  CLOSING: WebSocket.CLOSING,
  CLOSED: WebSocket.CLOSED
};
const waitConnection = Symbol("Connecting<Promise>");
const connectSuccess = Symbol("ConnectSuccess<resolve>");
const connectError = Symbol("ConnectError<reject>");

export class Socket extends Observer {
  constructor(url, opts) {
    super();
    this.url = url;
    this.config(opts);
    this.connect();
  }
  get pingMessage() {
    return this.options.pingMessage;
  }
  get pingInterval() {
    return this.options.pingInterval;
  }
  get readyState() {
    return this.socket ? this.socket.readyState : Status.UNINITIALIZED;
  }
  config(opts) {
    this.options = merge(
      {
        query: {},
        // 默认禁用 ping
        pingInterval: 0,
        pingMessage: "ping"
      },
      this.options,
      opts
    );
  }
  /**
   * promise 在 pending 状态时不会重置，因为在不正确的时机重置会导致 send 的 await 之后语句永远不会执行
   * 导致某些 send 操作需要发送的 chunk 丢失。为避免这个问题发生，这里通过 done 标识确保每个
   * promise 都会被 resolve 或 reject。（trick: 在 resolve 或 reject 后添加一个 done 标记)
   */
  resetStatus() {
    if (this[waitConnection] && !this[waitConnection].done) return;
    const promise = new Promise((resolve, reject) => {
      this[connectSuccess] = resolve;
      this[connectError] = reject;
    });
    this[waitConnection] = promise;
    promise
      .then(() => (promise.done = true))
      .catch(() => (promise.done = true));
  }
  connect(initialChunk) {
    // 等待连接开启后才可发送消息
    this.resetStatus();
    // 建立 WebSocket
    const query = this.options.query || {};
    const url = new URL(this.url, location.origin);
    url.protocol = url.protocol.includes("https") ? "wss" : "ws";
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    for (const key in query) {
      if (hasOwnProperty.call(query, key)) {
        url.searchParams.append(key, query[key]);
      }
    }
    const socket = new WebSocket(url);
    this.socket = socket;
    socket.onopen = evt => {
      // initialChunk 将在连接建立成功后立即发送
      if (initialChunk) {
        const chunk = this.serialize(initialChunk);
        this.socket.send(chunk);
      }
      this.onopen(evt);
    };
    // ⚠️ 注意 this 问题
    socket.onerror = evt => this.onerror(evt);
    socket.onclose = evt => this.onclose(evt);
    socket.onmessage = evt => {
      let data = null;
      try {
        data = JSON.parse(evt.data || null);
      } catch (err) {
        data = evt.data;
      }
      this.onmessage(evt, data);
    };
  }
  /**
   * reconnect, alias of connect
   */
  reconnect(initialChunk) {
    this.connect(initialChunk);
  }
  close(...args) {
    if (this.socket) {
      this.socket.close(...args);
    }
    // reject 掉后续用当前 socket 实例发送的所有数据块
    this[connectError]();
  }
  /**
   * 序列化要发送的数据
   * @param {any} data 发送的数据块
   * @return {String}
   */
  serialize(data) {
    return isObject(data) ? JSON.stringify(data) : data;
  }
  /**
   * 当前 socket 实例正在关闭或已关闭，则此次调用需发送的数据要等到下一次重连成功后自动发送
   * 这样设计是为了解决 ws 因网络中断等意外断开时调用了 send 导致该操作发送的数据块丢失问题
   * 并且借助 async/await 的特性，promise resolve 后，会自动执行发送动作
   * 将消息队列交给 js 执行环境维护，不用显示声明队列
   */
  async send(data) {
    // 若连接已关闭，则等待重连成功后再发送消息
    const state = this.readyState;
    if (state === Status.CLOSED || state === Status.CLOSING) {
      this.resetStatus();
    }
    this.onsend(data);
    try {
      await this[waitConnection];
      this.socket.send(this.serialize(data));
    } catch (err) {
      return err;
    }
  }
  ping() {
    // 利用闭包保存当前 socket 实例，避免重连后再次调用 ping() 导致发多个 ping 消息
    const socket = this.socket;
    const callback = () => {
      const pingInterval = this.pingInterval;
      if (!pingInterval) return;
      setTimeout(() => {
        // 检查闭包中的 socket 是否已经关闭，关闭后不在继续发送 ping 消息
        const opened = socket && socket.readyState === Status.OPEN;
        if (opened) {
          this.send(this.pingMessage);
          callback();
        }
      }, pingInterval);
    };
    callback();
  }
  // hooks
  onopen(evt) {
    this[connectSuccess]();
    this.emit("on-open", evt, this);
    this.ping();
  }
  onerror(evt) {
    this[connectError]();
    // 重连后才可继续发送消息
    this.resetStatus();
    this.emit("on-error", evt, this);
  }
  onclose(evt) {
    this[connectError]();
    // 重连后才可继续发送消息
    this.resetStatus();
    this.emit("on-close", evt, this);
  }
  onsend() {}
  onmessage(evt, data) {
    this.emit("on-message", evt, data, this);
  }
}

Socket.UNINITIALIZED = Status.UNINITIALIZED;
Socket.CONNECTING = Status.CONNECTING;
Socket.OPEN = Status.OPEN;
Socket.CLOSING = Status.CLOSING;
Socket.CLOSED = Status.CLOSED;

export default Socket;

<template>
  <!--
    @mousedown.stop 阻止事件冒泡，便于嵌套 contextify。
    例如在 contextify 中渲染组件 A，组件 A 中需要支持选择图标。此时选择图标时一般不会关闭组件 A 的 context
  -->
  <div @mousedown.stop>
    <ul v-delay class="grid-wrapper ndl-flex ndl-flex--wrap">
      <li
        v-for="item in activeIconList"
        :key="item"
        class="grid-cell ndl-flex ndl-flex--center ndl-flex--middle"
        @click="$emit('select', item)"
      >
        <IconFont prop="data-src" :type="item" />
      </li>
    </ul>
    <footer class="icon-pack-tabs">
      <span
        v-for="(item, index) in iconPackList"
        :key="index"
        :class="['tab-label', { active: options.tabIndex == index }]"
        @click="options.tabIndex = index"
        >{{ item.label }}</span
      >
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import IconFont from "@/components/iconfont";
import delay from "./v-delay";

const iconPackList = axios("/iconfonts/definitions.json").then(res => res.data);
const options = { tabIndex: 0 };

export default {
  components: { IconFont },
  directives: { delay },
  data() {
    return { options, iconPackList: [] };
  },
  computed: {
    activeIconList() {
      const pack = this.iconPackList[options.tabIndex] || {};
      return pack.icons || [];
    }
  },
  async created() {
    this.iconPackList = await iconPackList;
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

@size: 42px;

.grid-wrapper {
  margin: 0;
  padding: 8px 0 0 8px;
  border-radius: 4px;
  width: 355px;
  height: 185px;
  overflow: scroll;
  .grid-cell {
    width: @size;
    height: @size;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}
.icon-pack-tabs {
  // box-shadow: 0 -6px 12px -8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
  padding: 8px;
  font-size: 12px;
  color: @text-color-secondary;
  user-select: none;
  .tab-label {
    margin-right: 8px;
    border-radius: 4px;
    padding: 5px 8px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }
  .tab-label:hover {
    background-color: #f8f8f8;
  }
  .tab-label.active {
    color: @primary-color;
  }
}
</style>

import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";
import resolveReservedFields from "@/utils/resolve-reserved-field";

const axios = Axios.create({ baseURL: resolveURL("/api/datasheet") });

interceptor.auth(axios);

export function getDatasheetList(params) {
  return axios.get("/list", { params });
}

export async function getDatasheet(nodeId, params) {
  const res = await axios.get(`/${nodeId}`, { params });
  const data = res.data || {};
  data.fieldList = resolveReservedFields(
    data.fieldList || [],
    item => item.columnName
  );
  res.data = data;
  return res;
}

export function createDatasheet(data) {
  return axios.post("/create", data);
}

export function copyDatasheet(datasheetId) {
  return axios.post(`/copy/${datasheetId}`);
}

/**
 * 获取表格中的字段列表，结构和 websocket 中返回的 columns 一致
 * @param {{ datasheetId: string }} params
 */
export function getDatasheetColumns(params) {
  return axios.get("/columns", { params });
}

export function getAppSpecification(appType) {
  return axios.get(`/app/specification?appType=${appType}`);
}

export function getAppSchema(data) {
  return axios.post("/app/schema", data);
}

export function saveSchedule(data) {
  return axios.post("/app/push", data);
}

export function getScheduleList(params) {
  return axios.get("/app/push/list", { params });
}

export function getScheduleStatus(params) {
  return axios.get("/app/push/status", { params });
}

export function deleteSchedule(scheduleId) {
  return axios.delete(`/app/push/${scheduleId}`);
}

export function runSchedule(scheduleId) {
  return axios.get(`/app/push/run/${scheduleId}`);
}

export function stopSchedule(scheduleId) {
  return axios.get(`/app/push/cancel/${scheduleId}`);
}

export function resetSchedule(scheduleId) {
  return axios.get(`/app/push/reset/${scheduleId}`);
}

export function getScheduleHistory(params) {
  return axios.get("/app/push/tasks", { params });
}

export function getScheduleLog(taskId) {
  return axios.get(`/app/push/task/log/${taskId}`);
}

export function stopScheduleTask(taskId) {
  return axios.get(`/app/push/task/cancel/${taskId}`);
}

export function getOpenapiInfo(datasheetId) {
  return axios.get(`/${datasheetId}/openapi`);
}

export function getInvokeLog(datasheetId, params) {
  return axios.get(`/${datasheetId}/openapi/invokeLog`, { params });
}

export function updateOpenapiInfo(datasheetId, data) {
  return axios.put(`/${datasheetId}/openapi`, data);
}

export function exportCSV(datasheetId) {
  return axios.get(`/${datasheetId}/export/csv`);
}

export function getKinshipData(datasheetId) {
  return axios.get(`/${datasheetId}/dataBlood`);
}

export function getMaterializeDetail(params) {
  return axios.get("/materialize/details", { params });
}

export function saveMaterialize(data) {
  return axios.post("/materialize/save", data);
}

export function getMaterializeTasks(params) {
  return axios.get("/materialize/tasks", { params });
}

export function getMaterializeTasksLog(taskId) {
  return axios.get(`/materialize/task/log/${taskId}`);
}

export function getMaterializeRun(materializeId) {
  return axios.get(`/materialize/run/${materializeId}`);
}

export function delMaterialize(materializeId) {
  return axios.delete(`/materialize/${materializeId}`);
}

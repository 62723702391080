<template>
  <div v-if="isPlaceholder" :class="['placeholder', size]"></div>
  <div
    v-else
    :class="['focusable', size, { focus }]"
    @click.stop="$emit('click', $event)"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isPlaceholder: Boolean,
    size: String,
    focus: Boolean
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

@size-small: 20px;
@size-default: 24px;
@size-large: 28px;

.placeholder,
.focusable {
  width: @size-default;
  height: @size-default;
  & + & {
    margin-left: 3px;
  }
  &.small {
    width: @size-small;
    height: @size-small;
  }
  &.large {
    width: @size-large;
    height: @size-large;
    font-size: 16px;
  }
}
.focusable {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: background-color 0.2s;
  color: rgba(55, 53, 47, 0.65);
  outline: none;
  cursor: pointer;
  &:hover,
  &.focus {
    background: rgba(0, 0, 0, 0.06);
  }
}
</style>

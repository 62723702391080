<template>
  <span class="node-title">
    <input
      v-if="node.nodeStatus.renaming"
      v-focus
      type="text"
      :value="node.nodeName"
      @blur="onBlur"
      @keydown.enter="onEnter"
      @keydown.esc="onEsc"
    />
    <template v-else>{{ node.nodeName }}</template>
  </span>
</template>

<script>
import { focus } from "@/utils/directives";
import TreeNode from "./model-tree";

export default {
  props: {
    node: TreeNode
  },
  directives: { focus },
  methods: {
    onBlur(evt) {
      this.node.setName(evt.target.value);
      this.node.nodeStatus.renaming = false;
    },
    onEnter(evt) {
      evt.target.blur();
    },
    onEsc(evt) {
      evt.target.value = this.node.nodeName;
      evt.target.blur();
    }
  }
};
</script>

<style lang="less" scoped>
.node-title {
  flex: 1 1 0;
  width: 0;
  padding: 0 3px 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  input {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 3px;
    padding: 3px 8px;
    background: rgba(0, 0, 0, 0.06);
  }
}
</style>

<template>
  <img :[prop]="src" class="icon-symbol" />
</template>

<script>
export default {
  props: {
    type: String,
    // 可自定义初始化属性，例如 `data-src` 用于懒加载
    prop: {
      type: String,
      default: "src"
    }
  },
  computed: {
    src() {
      return this.type ? `/iconfonts/${this.type}` : null;
    }
  }
};
</script>

<style lang="less" scoped>
.icon-symbol {
  width: 1em;
  height: 1em;
}
</style>

import Socket from "@/utils/socket";
import token from "@/utils/token";
import isObject from "lodash/isObject";
import { random } from "@/utils/string";
import { flatten, hashmap } from "@/utils/array";
import Column from "@/model/column";

// 服务端消息通过 notification 提示
import notification from "@/utils/notification-box";
// ws 异常消息通过 message 提示
import { message } from "ant-design-vue";

const WaitingChunk = Symbol.for("waiting chunk");

/**
 * 判断响应是否为错误消息
 * @param {Object} response 响应实体
 * @returns {Boolean} 是否为服务器错误消息
 */
function isError(response) {
  return response.code === 500 || response.code === "500";
}

export default class WorksheetSocket extends Socket {
  constructor(params) {
    super("/widget", {
      query: { ...params, token: token.value() },
      pingInterval: 10000
    });
    // 计数器，实现视图正在加载
    this.loadingCounter = {};
    // 调用 send 后返回的 promise 将会在后端响应完之后 resolve
    this[WaitingChunk] = {};
    this.sessionId = null;
    this.datasheetId = null;
  }

  async updateToken() {
    await token.check();
    this.config({
      query: { token: token.value() }
    });
  }

  /**
   * 前端一次请求后端可能会有多个响应，且多个视图间的响应顺序不能保证
   * 因此用视图 id 作为键名计数，发送一次对应视图计数器 +1
   * 收到对应视图响应 done: true 时，计数器 -1，当计数器为 0 时就认为加载完成
   * @param {Boolean} loading 是否启用 loading
   * @param {String} datasheetId 视图 id
   */
  loadingChunk(loading = false, datasheetId) {
    const key = datasheetId || "default";
    const counter = this.loadingCounter || {};
    // 初始化计数器 为 0
    if (!counter[key] || counter[key] < 0) counter[key] = 0;
    if (loading) {
      counter[key] += 1;
      return this.emit("update-loading", true, datasheetId);
    }
    counter[key] -= 1;
    if (counter[key] <= 0) this.emit("update-loading", false, datasheetId);
  }
  /**
   * 通过 hash 标记请求消息，当该请求响应时 resolve 或 reject
   * @param {Object} chunk 发送或接收的消息实体
   * @param {Boolean} done 是请求还是响应
   * @returns {Promise}
   */
  waitChunk(chunk, done = false) {
    if (!done) {
      // 每次操作前端发送的数据会分配一个 hash 标识 chunk 的唯一性
      chunk.hash = random();
      return new Promise((resolve, reject) => {
        this[WaitingChunk][chunk.hash] = { resolve, reject };
      });
    }
    const promise = this[WaitingChunk][chunk.hash];
    if (!promise) return Promise.resolve(chunk);
    return isError(chunk) ? promise.reject(chunk) : promise.resolve(chunk);
  }

  /**
   * 发送消息
   * @param {String|Object} chunk 发送的消息实体
   * @return {Promise}
   */
  async send(chunk) {
    // 非对象情况，如 ping 消息，无需处理
    if (!isObject(chunk)) return super.send(chunk);
    // chunk 的 disableLoading 标记表示此发送动作不会显示加载动画，静默请求
    if (!chunk.disableLoading) this.loadingChunk(true, this.datasheetId);
    const promise = this.waitChunk(chunk);
    super.send(chunk);
    return promise;
  }

  /**
   * 接收消息后以事件形式通知其他组件
   * @param {Event} evt 消息事件
   * @param {Object|String} response 消息内容
   */
  onmessage(evt, response = {}) {
    super.onmessage(evt);
    // pong 响应不处理
    if (response === "pong") return;
    // 有些操作会分多种 chunk 响应。接受到的 chunk done 为 true
    // 表示相应操作已全部响应，可以更新 loading 状态
    if (response.done) {
      this.loadingChunk(false, this.datasheetId);
      this.waitChunk(response, true);
    }
    if (isError(response)) {
      notification.show({
        type: response.errorLevel,
        message: response.message || "操作失败",
        description: response.description || response.msg || ""
      });
      return response;
    }
    const { data, datasheetId = this.datasheetId } = response;
    this.sessionId = response.sessionId;

    const worksheetFields = [
      // [fieldName, adapter]
      ["columns", params => (params || []).map(Column.from)],
      ["undoable", params => !!params],
      ["redoable", params => !!params],
      ["transpose"],
      ["filter"],
      ["group"],
      ["sort"],
      ["query"],
      ["joins"],
      ["topn"]
    ];
    switch (response.dataType) {
      /**
       * 返回的是工作表结构
       */
      case "worksheet":
        for (const [fieldName, fieldAdapter] of worksheetFields) {
          const evtName = "update-" + fieldName;
          const payload = fieldAdapter
            ? fieldAdapter(data[fieldName])
            : data[fieldName] || null;
          this.emit(evtName, payload, datasheetId);
        }
        break;
      /**
       * 返回的是工作表数据
       */
      case "data":
        // data.groups 是分组数据，其中每个分组的 stats 是分组统计数据
        // 分组统计后端返回为数组类型，前端使用需转换为表结构，易于查找匹配
        flatten(data.groups, "groups").forEach(item => {
          item.statistics = hashmap(item.stats, "columnId");
        });
        this.emit("update-data", data, datasheetId);
        break;
      case "stats":
        this.emit(
          "update-statistics",
          hashmap(data.stats, "columnId"),
          datasheetId
        );
        break;
      /**
       * 返回的是工作表记录数，可根据记录数判断是否加载完所有数据
       */
      case "total":
        this.emit("update-total", data, datasheetId);
        break;
    }
  }

  /**
   * 发送消息前检查连接是否断开，断开则重连
   */
  onsend(chunk) {
    const state = this.readyState;
    // 若连接已关闭，则等待重连成功后再发送消息
    if (state === Socket.CLOSED || state === Socket.CLOSING) {
      this.emit("on-reconnect", chunk, this);
    }
  }
  /**
   * 主动关闭连接时不需要提示，清除 onclose listener就好了
   */
  close(...args) {
    if (this.socket) {
      this.socket.onclose = null;
    }
    super.close(...args);
  }
  /**
   * 被动关闭时，弹出异常提示
   */
  onclose(evt) {
    super.onclose(evt);
    // ⚠️ 不停地尝试 send 但又没消息返回，会导致计数器不断自增
    // 需要在 close 时重置计数器，否则后续重连成功计数器异常
    this.loadingCounter = {};
    console.group("ws closed at:", new Date());
    console.log("event", evt);
    console.log("datasheetId", this.datasheetId);
    console.groupEnd();
    message.error("网络异常");
  }
  onerror(evt) {
    console.group("ws error at:", new Date());
    console.log("details", evt);
    console.log("datasheetId", this.datasheetId);
    console.groupEnd();
    super.onerror(evt);
  }
}

// 每页数量
export const PAGE_SIZE = Math.ceil(screen.height / 32) * 10;
WorksheetSocket.PAGE_SIZE = PAGE_SIZE;
WorksheetSocket.isError = isError;
